
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { OAuthService } from 'angular-oauth2-oidc';

/**
 * Empty component used only by angular-oauth2-oidc
 */
@Component({
    selector: 'app-auth-callback',
    templateUrl: './auth-callback.component.html'
})
export class AuthCallbackComponent implements OnInit {

    constructor(private oauthService: OAuthService,private router:Router) {
    }

    public ngOnInit() {
        // In our case with angular-oauth2-oidc, Auth Callback was called before the
        // Token was delivered.
        // The message pump is in app.component.ts, and it is the only place where
        // token_received is processed.
        // Therefore, we do absolutely nothing in AuthCallback, except register it
        // with APIGEE/OKTA, and angular-oauth2-oidc calls it outside of the normal
        // routing mechanism, so it nothing "lands" here from navigation's perspective
    }
}
