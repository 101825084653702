import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { EditorModule } from '@progress/kendo-angular-editor';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs'; // Contains ColorPickerModule
import { LabelModule } from '@progress/kendo-angular-label';
import { UploadModule } from '@progress/kendo-angular-upload';
import { PopupModule } from '@progress/kendo-angular-popup';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { TreeViewModule } from '@progress/kendo-angular-treeview';

import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { ServiceModule } from '@services/service.module';
import { httpInterceptorProviders } from './http-interceptors/index';
import { RegistrationTextValidatorDirective } from './employers/registration-text-validator/registrationtext.validator.directive';
import { SecurePipe } from './shared/pipes/secure.pipe';

import { EmployersComponent } from './employers/employers.component';
import { EmployersListComponent } from './employers/employers-list/employers-list.component';
import { EmployersDetailsComponent } from './employers/employers-details/employers-details.component';
import { UploadComponent } from './employers/employers-details/upload.component';
import { EmployersMessagesComponent } from './employers/employers-messages/employers-messages.component';
import { EscfgComponent } from './escfg/escfg.component';
import { LoginComponent } from './authentication/login/login.component';
import { LogoutComponent } from './authentication/logout/logout.component';
import { DeleteAccountComponent } from './deleteaccount/deleteaccount.component';

import { AlertComponent } from './shared/alert/alert.component';

import { RoleGuardService } from '@services/role-guard.service';
import { AuthGuardService } from '@services/auth-guard.service';

import { MenuCode } from '@models/menu-code.model';
import { CanDeactivateGuard } from './unsaved-changes-guard/can-deactivate/can-deactivate.guard';

// TODO: Introduce Environment handling
const enableRouteTracing:boolean = false;
const activationServices = [AuthGuardService, RoleGuardService];

const routes: Routes = [
    { path: '', component: HomeComponent, pathMatch: 'full' },

    { path: 'auth-callback', component: AuthCallbackComponent },
    { path: 'login', component: LoginComponent },
    { path: 'logout', component: LogoutComponent },
    { path: 'deleteaccount', component: DeleteAccountComponent, 
        canActivate: activationServices, // Prevent auto redirect from auth-guard.services
        data : {
            unauthorizedPage: true // Can access without login and doesn't show navigation bar
        } 
    },
    // ESER (it turns out to be really hard to name the outlet for clarity)
    {
        path: 'employers',
        component: EmployersComponent,
        canActivate: activationServices,
        data: { menuCode: MenuCode.ESER },
        children: [
            { path: '', redirectTo: "list", pathMatch: 'full' },
            { path: 'list', component: EmployersListComponent },
            {
                path: 'details/:id',
                component: EmployersDetailsComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'messages/:id',
                component: EmployersMessagesComponent,
                canDeactivate: [CanDeactivateGuard]
            }
        ]
    },
    {
        path: 'escfg',
        component: EscfgComponent,
        canActivate: activationServices,
        data: { menuCode: MenuCode.ESCFG },
        canDeactivate: [CanDeactivateGuard]
    },
    // Some OIDC docs say we need a catch all.
    {
        path: '**',
        redirectTo: ''
    }
];


@NgModule({
    declarations: [
        AppComponent,
        NavMenuComponent,
        AuthCallbackComponent,
        HomeComponent,
        EmployersComponent,
        EmployersListComponent,
        EmployersDetailsComponent,
        EmployersMessagesComponent,
        EscfgComponent,
        LoginComponent,
        LogoutComponent,
        DeleteAccountComponent,
        UploadComponent,
        RegistrationTextValidatorDirective,
        AlertComponent,
        SecurePipe
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        OAuthModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forRoot(
            routes,
            {
                onSameUrlNavigation: "ignore", // "reload" allows clicking the current URL to fire an event
                enableTracing:enableRouteTracing
                /*, initialNavigation:false, useHash:true*/
            }
        ),
        ButtonsModule,
        BrowserAnimationsModule,
        GridModule,
        InputsModule,
        DialogsModule,
        LabelModule,
        DragDropModule,
        DropDownsModule,
        ServiceModule,
        UploadModule,
        PopupModule,
        NotificationModule,
        TooltipModule,
        TreeViewModule,
        EditorModule
    ],
    exports: [RouterModule],
    providers: [
        httpInterceptorProviders,
        CanDeactivateGuard
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
