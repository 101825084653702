import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

// https://github.com/IdentityModel/IdentityModel.OidcClient2
// https://manfredsteyer.github.io/angular-oauth2-oidc/docs/additional-documentation/working-with-httpinterceptors.html

// Other libraries:
// https://damienbod.com/2019/01/09/securing-angular-applications-using-the-openid-connect-code-flow-with-pkce/

export const authCodeFlowConfig: AuthConfig = {
    // Url of the Identity Provider
    //issuer: 'https://pdisoftware-engineering-dev.apigee.net/cp-id-auth/',
    //issuer: 'https://pdisoftware-engineering-dev.apigee.net/cp-id-auth/',
    //issuer: 'https://demo22-test.apigee.net/cp-id-auth/',
    issuer: '', // Set in code via EnvironmentService

    // URL of the SPA to redirect the user to after login
    //redirectUri: window.location.origin + '/employers/list',
    redirectUri: window.location.origin + '/auth-callback',

    // The SPA's id. The SPA is registerd with this id at the auth-server
    // (APIGEE client ID)
    // clientId: 'VCmAOjeu7QVSKYK5GKJnQi9LGKgpbQfy', useFactory: getBaseUrl, deps: [] }
];

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .catch(err => console.log(err));
