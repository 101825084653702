import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public title: string = 'app';
  currentUrl: string = '';
  config: any;

  constructor(private authService: AuthService, private router: Router) {
    this.config = this.router.config;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.urlAfterRedirects.replace('/','');
      }
    });
    
    this.authService.initialize();
  }

  ngOnInit(): void {}

  isUnauthorizedPage(): boolean {
    if(this.config != null){
      let selectedConfig = this.config.find(x => x.path.includes(this.currentUrl));
      if( selectedConfig != null && selectedConfig.data != null && selectedConfig.data.unauthorizedPage == true){
        return true;
      }
    }
    return false;
  }
}